/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

//
.nc-card-title {
  @apply transition-colors hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.z-max {
  z-index: 99999999;
}
//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

//
.nc-PostFeaturedMedia {
  .glide__bullet--active {
    @apply bg-white bg-opacity-100;
  }
}

.modalPhotos-single-gallery {
  .controls_nav {
    .glide__bullet--active {
      display: block;
    }
  }
}

// LOADING
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

//

.nc-animation-spin {
  animation: myspin 20s linear infinite;
  animation-play-state: paused;

  &.playing {
    animation-play-state: running;
  }

  @keyframes myspin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.nc-section-rounded-none {
  *[class*="rounded"],
  *[class*="nc-box"] {
    border-radius: 0 !important;
  }
}

.nc-section-rounded-md {
  *[class*="rounded"],
  *[class*="nc-box"] {
    @apply rounded-md #{!important};
  }
}

.nc-google-shadow {
  box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%);
}

//

//
.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-CardLarge1--hasAnimation {
  .nc-CardLarge1__left {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
  }
  .nc-CardLarge1__right {
    animation-name: CardLarge1ExampleRight;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
  }
}

@keyframes CardLarge1ExampleRight {
  from {
    transform: scale(1.05) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes CardLarge1ExampleLeft {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.nc-custom-shadow-1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.spinner-loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner-loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(255,255,255,0.5);
  background-color: rgba(231, 230, 230, 0.8);
  opacity: 0.7;
}

.spinner-body-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
}

.bg-dark {
  background-color: #2ecc71;
}
